import React from 'react';
import './dashboard.less';
import User from '@/types/user'
import { useSelector } from 'react-redux';
import {Redirect, Route} from 'react-router-dom';
import { Routers } from '@/route/config';
import LayoutInit from '../Layout/layout';
const Auth:React.FC = (props: any) => {
    // const user: User = useSelector(state => state)
    const user: User = {
        userId: '23232',
        username: 'string',
        password: 'string'
    }
    let { location: { pathname } } = props;
    const targetRouter: any = Routers.find((key: any) => key.path === pathname || key.redirect === pathname)
    // if (!targetRouter) { // 如果找不到页面
    //     return (<Redirect to='/404'/>)
    // }
    if (!user.userId) { // 如果没登录 则去登录
        return (<Redirect to='/login'/>)
    } else {
        if (location.pathname === '/') { // 如果是首页
            return (<Redirect to="/home"/>)
        } else {
            return (<Route><LayoutInit/></Route>)
        }
    }



    // if () { // 如果发生了服务器端错误
    //     return (<Route path='*' component={View500}/>)
    // }
}

export default Auth;
