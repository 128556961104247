import { registerMicroApps ,start } from 'qiankun';

export default function startQinKun() {
    const prodConfig = [
        {
            name: 'vue-admin-template', // app name registered
            entry: '//webyangpei.com:8801',
            container: '#app',
            activeRule: '/admin-vue',
        }
    ]
    const devConfig = [
        {
            name: 'vue-admin-template', // app name registered
            entry: '//localhost:9528',
            container: '#app',
            activeRule: '/admin-vue',
        }
    ]
    // 注册微应用
    registerMicroApps(process.env.NODE_ENV === 'development' ? devConfig : prodConfig);
	
    // setDefaultMountApp('/home')

    // 启动乾坤
    start({
        prefetch: false
    });
}


