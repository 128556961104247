import {Space, Spin} from 'antd';
import commonStyle from '../styles/common.module.css';
import React from 'react';

const Loading = () => (
    <Space size="middle" className={commonStyle.loading}>
        <Spin size="large"/>
    </Space>
)

export default Loading