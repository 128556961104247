import { createStore, combineReducers  } from 'redux';
import user from './user'
import home from './home'
const todoApp = combineReducers({
    home,
    user
})
const store = createStore(todoApp);
export default store;


