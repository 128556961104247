import React, {Suspense} from 'react';
import './styles/index.less';
import {BrowserRouter, Redirect, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import store from './store';
import Auth from './views/auth';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn'
import Loading from './components/Loading';
import {staticRouter} from './route/config';
// 由于 antd 组件的默认文案是英文，所以需要修改为中文
dayjs.locale('zh-cn')
import zhCN from 'antd/es/locale/zh_CN';
import en_GB from 'antd/es/locale/en_GB';
import en_US from 'antd/es/locale/en_US';
import ja_JP from 'antd/es/locale/ja_JP';

const App = () => {
    return (
        <ConfigProvider locale={en_US}>
            <Provider store={store}>
                <BrowserRouter>
                    <Switch>
                        {
                            staticRouter.map(({ path, redirect, component: Comp  }: any) => {
                                return (
                                    <Route
                                        exact
                                        key={path}
                                        path={path}
                                        render={() =>{
                                            return <Suspense fallback={Loading()}>
                                                {
                                                    redirect
                                                        ? <Redirect to={redirect}/>
                                                        : <Comp />
                                                }
                                            </Suspense>
                                        }}
                                    />
                                )
                            })
                        }
                        <Auth/>
                    </Switch>
                </BrowserRouter>
            </Provider>
        </ConfigProvider>
    )
}
export default App;
