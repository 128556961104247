// state 信息
const count: number = 0;

// action
const homeAction = {
    SET_COUNT: (count: number) => {
        return {
            type: homeAction.SET_COUNT,
            count
        }
    }
}


// reducer
export default function home(state: number = count, action: any) {
    switch (action.type) {
        // 更新用户名密码
        case homeAction.SET_COUNT: return action.count
        default: return state
    }
}