import React from 'react';
import logo from '@/assets/img/icon-no-shop-logo.png'

const Logo = () => {
    let imgStyle = {
        height: '64px',
        width: '100%',
        backgroundColor: '#fff'
    }
    return (
        <img alt="" style={imgStyle} src={logo}/>
    )
};

export default Logo
