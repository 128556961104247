import React from 'react';
import { UserOutlined, StarOutlined, HomeOutlined, DashboardOutlined, BookOutlined } from '@ant-design/icons'
const vueAdminRouter = [
    {
        path: '/admin-vue/*',
        title: '子首页',
        icon:  () => <DashboardOutlined />,
        children: [{
            path: '/admin-vue/dashboard',
            title: '子首页',
            component: React.lazy(() => import('../Layout/child'))
        }]
    },
]
const asyncRouter = [
    {
        name: 'home',
        path: '/',
        icon:  () => <HomeOutlined />,
        title: '首页',
        redirect: '/home',
        hideChildrenInMenu: false,
        hideInMenu: false,
        authority: false, // 用来验证当前路由的权限
        component: React.lazy(() => import('../views/dashboard'))
    },
    {
        name: 'book',
        path: '/book',
        redirect: '/book/book-list',
        icon:  () => <BookOutlined />,
        title: '书籍',
        children: [
            {
                name: 'bookList',
                path: '/book/book-list',
                title: '图书列表',
                component: React.lazy(() => import('../views/books/books-list'))
            }
        ]
    },
    {
        name: 'algorithm',
        path: '/algorithm',
        redirect: '/algorithm/algorithm-list',
        icon:  () => <StarOutlined />,
        title: '算法',
        children: [
            {
                name: 'algorithm',
                path: '/algorithm/algorithm-list',
                icon: 'LaptopOutlined',
                title: '算法列表',
                component: React.lazy(() => import('../views/algorithm/algorithm-list'))
            }
        ]
    },
    ...vueAdminRouter
]


export const staticRouter = [
    {
        name: 'login',
        path: '/login',
        icon:  () => <UserOutlined />,
        title: '登录',
        authority: false, // 用来验证当前路由的权限
        component: React.lazy(() => import('../views/login'))
    },
    {
        name: '404',
        path: '/404',
        icon:  () => <UserOutlined />,
        title: '404',
        component: React.lazy(() => import('../views/View404'))
    }
]

// 所有的路由拷贝
export const Routers = [
    ...asyncRouter,
    ...staticRouter
]

export default asyncRouter;
