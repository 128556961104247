import React from 'react';
import { Menu } from 'antd';
import asyncRouter from '../../route/config'
import { NavLink } from 'react-router-dom';
const { SubMenu } = Menu;
const Sidebar = () => {
    const createMenuLink = (route:any) => {
        if(route.children && route.children.length) {
            return (
                <SubMenu
                    key={route.path}
                    title={route.title}
                    icon={route.icon()}
                >
                    {route.children.map((child:any) => {
                        return (
                            <Menu.Item key={child.path}>
                                <NavLink to={child.path}>
                                    <span>{child.title}</span>
                                </NavLink>
                            </Menu.Item>)
                    })}
                </SubMenu>
            )
        } else {
            return (
                <Menu.Item key={route.path}>
                    <NavLink to={route.path}>
                        <route.icon />
                        <span>{route.title}</span>
                    </NavLink>
                </Menu.Item>
            )
        }
    }
    return(
        <Menu
            mode="inline"
            style={{ height: 'calc(100% - 64px)', borderRight: 0 }}
            defaultSelectedKeys={['/']}
        >
            {asyncRouter.map(createMenuLink)}
        </Menu>
    )
}

export default Sidebar;
