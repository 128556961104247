import React from 'react';
import {Layout} from 'antd';
import MainContent from '../views/mainContent';
import Sidebar from './Sidebar'
import EnBreadcrumb from '../components/EnBreadcrumb';
import EnTool from './entool';
import Logo from './Logo';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import 'nprogress/nprogress.css'
import './layout.less'
import asyncRouter from '../route/config'
const {Header, Sider, Content} = Layout;
import { MenuFoldOutlined } from '@ant-design/icons';


const LayoutInit:React.FC = () => {
    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                width={200}
                collapsible
                theme="light"
                collapsedWidth={50}
                trigger={<MenuFoldOutlined/>}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    textAlign: 'left',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
              >
                <Logo/>
                <Sidebar/>
            </Sider>
            <Layout>
                <Header style={{
                    paddingLeft: '210px',
                    background: '#fff',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'space-between'
                }}>
                    <EnBreadcrumb menu={asyncRouter}/>
                    <EnTool />
                </Header>
                <Content style={{height: 'calc(100vh - 64px)'}}>
                    <MainContent/>
                </Content>
            </Layout>
        </Layout>
    )
}

export default connect()(withRouter(LayoutInit));
