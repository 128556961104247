import React, {Suspense} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';
import asyncRouter from '../route/config'
import Loading from '../components/Loading';

const MainContent = () => {

    const createMenu = (route: any) => {
        if (route && route.children && route.children.length) {
            return route.children.map(createMenu)
        } else {
            return (
                <Route
                    exact
                    key={route.path}
                    path={route.path}
                    render={props =>{
                        return <Suspense fallback={Loading()}>
                            {
                                route.redirect
                                    ? <Redirect to={route.redirect}/>
                                    : <route.component {...props} routes={route}/>
                            }
                        </Suspense>
                    }}
                />
            )
        }
    }
    return (
        <Switch>
            {asyncRouter.map(createMenu)}
        </Switch>
    )
}

export default MainContent;
