import User from '@/types/user'

// state 信息
const userInfo: User = {
    username: '',
    password: ''
}

// action
const userAction = {
    SET_USER: (userInfo: User) => {
        return {
            type: userAction.SET_USER,
            userInfo
        }
    }
}


// reducer
export default function user(state: User = userInfo, action: any) {
    switch (action.type) {
        // 更新用户名密码
        case userAction.SET_USER:
            return {
                ...state,
                ...action.userInfo
            }
        default: return state
    }
}