import React from 'react';
import type { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { connect } from 'react-redux';
import { DownOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom';
import './entool.less'

const EnTool = () => {
    const history = useHistory()
    const onClick: MenuProps['onClick'] = ({ key }: any ) => {
        // 用户中心
        if (key === 'userCenter') {
            history.push('/userCenter')
        }
        // 退出登录
        if (key === 'logout') {
            console.log('如果点击此按钮，即代表要执行退出登录')
            history.push('/login')
        }
    };
    const items = [
        { label: '个人中心', key: 'userCenter' }, // 菜单项务必填写 key
        { label: '退出登录', key: 'logout' },
    ];
    return (
            <Dropdown menu={{ items, onClick }}>
                <a onClick={e => e.preventDefault()}>
                    <Space>
                        <img className="login-user" src="https://gw.alipayobjects.com/zos/rmsportal/BiazfanxmamNRoxxVxka.png" alt=""/>
                        <span>用户名</span>
                        <DownOutlined />
                    </Space>
                </a>
            </Dropdown>
    )
};

export default connect()(EnTool);
